class ProjectService {

    getTypeMapping() {
        return {
            dev: 'Développeur',
            commercial: 'Commercial',
            devops: 'DevOps',
            cto: 'Direction Technique',
            cdp: 'Gestion de projet'
        };
    }

    getTypes() {
        return [
            { name: 'Développeur', type: 'dev' },
            { name: 'Commercial', type: 'commercial' },
            { name: 'DevOps', type: 'devops' },
            { name: 'Direction Technique', type: 'cto' },
            { name: 'Gestion de projet', type: 'cdp' },
        ];
    }
}

export default new ProjectService();