<template>
    <div class="card timeline">
        <h5>Timeline de la semaine {{totalDay}}/5 jours</h5>
        <ul>
            <li v-for="item in entries" :key="item.id">
                <div class="p-grid">
                    <div class="p-col-fixed">
                        <div class="timeline-icon">
                            <i class="pi pi-map-marker"></i>
                        </div>
                    </div>
                    <div class="p-col">
                        <div class="time-line-event">
                            <span class="timeline-event-title">Temps projet <b>{{item.projectItem.project.name}}</b></span>
                            <div class="timeline-event-message">{{item.count}} jour en <b>{{types[item.projectItem.type]}}</b>
                            </div>
                            <div class="timeline-event-time">
                                {{formatDate(item.date)}}
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import ProjectService from '../../service/ProjectService'
import moment from 'moment';

export default {
    data() {
        return {
            entries: [],
            types: {},
            totalDay: 0 
        }
    },    
    created() {
        this.types = ProjectService.getTypeMapping();
    },
    mounted() {
        this.loadData();
    },
    methods: {
        loadData() {
            this.$resources.projectUserEntries.list(0, {
                'owner': this.$store.getters.getUser.id, 
                'date[after]': moment().startOf('isoWeek').format('YYYY-MM-DD'),
                'order[date]': 'desc'
                }).then((response) => {
                    this.entries = response.data;
                    this.entries.forEach((item) => {
                        this.totalDay += item.count;
                    })
                });
        },
        formatDate(date) {
            return moment(date).format('DD/MM/YYYY')
        },
    }
}
</script>
<style scoped>

</style>