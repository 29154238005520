<template>
    <div class="p-grid dashboard">
        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="overview-box box-sales">
                <form id="time-form" @submit.prevent="saveItem()">
                    <div class="overview-box-icon">
                        <i class="pi pi-clock"></i>
                    </div>
                    <div class="overview-box-title">Mes Heures</div>
                    <div class="overview-box-value">
                        <div style="display:flex;">
                            <div>
                                <AutoComplete v-model="entry.project" field="name" :suggestions="projects" @complete="searchProject($event)" placeholder="Projet" dropdown="true"> </AutoComplete>
                                <AutoComplete v-model="entry.projectItem" field="name" :suggestions="items" @complete="searchType($event)" placeholder="Type" dropdown="true" :disabled="!entry.project"> </AutoComplete>
                            </div>
                            <div class="overview-box-value" style="margin-top:-8px">
                                <Calendar v-model="entry.date" selectionMode="single" dateFormat="dd/mm/yy" :maxDate="maxDate" :showIcon="true" placeholder="Date" />
                                <InputNumber v-model="entry.count" mode="decimal" :minFractionDigits="2" placeholder="0,00 jour"  suffix=" jour"/>
                            </div>
                        </div>
                    </div>
                    <div class="p-mb-2">
                        <Button label="Ajouter" icon="pi pi-plus" iconPos="right" type="submit" from="time-form" :disabled="v$.$invalid" />
                    </div>
                </form>
            </div>
        </div>
        <div class="p-col-12 p-md-6 p-lg-3">
            <div class="overview-box  box-views">
                <div class="overview-box-icon">
                    <i class="pi pi-search"></i>
                </div>
                <div class="overview-box-title">Views</div>
                <div class="overview-box-value">7029</div>
                <div class="overview-box-status"><b>7%</b> more than yesterday</div>
            </div>
        </div>
        <div class="p-col-12 p-md-6 p-lg-3">
            <div class="overview-box  box-users">
                <div class="overview-box-icon">
                    <i class="pi pi-user"></i>
                </div>
                <div class="overview-box-title">Users</div>
                <div class="overview-box-value">9522</div>
                <div class="overview-box-status"><b>12%</b> more than yesterday</div>
            </div>
        </div>

        <div class="p-col-12 p-lg-8">
            <ProjectEntryTimeline v-if="getUser.id"></ProjectEntryTimeline>
        </div>
    </div>

</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, between } from '@vuelidate/validators';
import ProjectService from '../service/ProjectService'
import moment from 'moment';
import ProjectEntryTimeline from './project/ProjectEntryTimeline'
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            items: {},
            types : {},
            projects: [],
            entries: [],
            entry: {},
            maxDate: null
        };
    },
    emits: ['onUpdate'],
    components: {
        "ProjectEntryTimeline": ProjectEntryTimeline
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
        return {
            entry: {
                projectItem: { required },
                count: {required, betweenValue: between(0.1, 1) },
                date: { required}
            }
        };
    },
    computed: {
		...mapGetters(['getUser'])
	},
    created() {
        this.types = ProjectService.getTypeMapping();
        this.maxDate = new Date();
    },
    methods: {
        searchProject($event) {
            this.$resources.projects.list(0, { name: $event.query, status: 'progress' }).then(response => {
                this.projects = response.data;
            });
        },
        searchType($event) {
            this.$resources.projectItems.list(0, { type: $event.query, project: this.entry.project.id }).then(response => {
                this.items = response.data.map((item) => {
                    item.name = this.types[item.type];
                    return item;
                });
            });
        },
        saveItem() {
                const data = {
                    count: String(this.entry.count),
                    projectItem: this.entry.projectItem['@id'],
                    date: moment(this.entry.date, "DD-MM-YYYY").format('YYYY-MM-DD')
                }

                this.$resources.projectUserEntries.create(data).then(() => {
                    this.entry = {};
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Mission ajoutée', life: 3000 });
                });
        },
    },
};
</script>

<style scoped>
element.style {
}
.p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 2.357rem;
    color: white;
}
</style>
